import { KeyboardArrowUp, KeyboardArrowDown, Add } from "@mui/icons-material";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AddActionItemModal from "./modals/AddActionItemModal";
import TaskViewEditModal from "./modals/TaskViewEditModal";
import { taskTypes } from "../hooks/constants";

export default function ActionItemsBox({
  tasks,
  formSubmit,
  handleNewTaskAdd,
  handleRemoveDeletedTask,
  form,
  setTasks,
  handleSaveReorder,
}) {
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const [selectedTaskModal, setSelectedTaskModal] = useState(false);
  const [dragItemIndex, setDragItemIndex] = useState();
  const [dragOverItemIndex, setDragOverItemIndex] = useState();

  const firstPending = tasks.findIndex((c) => c.status === "Pending");

  const handleDragStart = (idx) => {
    setDragItemIndex(idx);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (idx) => {
    const _tasks = [...tasks];
    const dragItem = _tasks.splice(dragItemIndex, 1)[0];
    _tasks.splice(idx, 0, dragItem);
    const newTasks = _tasks.map((_task, i) => ({
      ..._task,
      order: i + 1,
    }));
    setTasks(newTasks);
    handleSaveReorder(newTasks);
  };

  const handleDragEnter = (idx) => {
    console.log("setting", idx);
    setDragOverItemIndex(idx);
  };

  const handleDragLeave = () => {
    setDragOverItemIndex(undefined);
  };

  const handleDragEnd = () => {
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  const handleTaskClick = (_task) => {
    setSelectedTask(_task);
    setSelectedTaskModal(true);
  };

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 2,
        width: "100%",
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: open ? 1 : 0,
          paddingBottom: open ? 1 : 0,
        }}
      >
        <Typography variant="h6">Tasks</Typography>
        <Box>
          <IconButton onClick={() => setOpenModal(!openModal)}>
            <Add />
          </IconButton>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            maxHeight: "250px",
            overflow: "scroll",
          }}
        >
          {tasks.map((task, idx) => (
            <div
              draggable
              onDragStart={() => handleDragStart(idx)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(idx)}
              onDragLeave={handleDragLeave}
              onDragEnd={handleDragEnd}
              onDragEnter={() => handleDragEnter(idx)}
              style={{
                marginTop: 8,
                marginBottom: 8,
                borderRadius: 8,
              }}
            >
              <Box
                onClick={() => handleTaskClick(task)}
                sx={{
                  border: 1,
                  borderRadius: 2,
                  padding: 1,
                  backgroundColor:
                    // eslint-disable-next-line no-nested-ternary
                    task.status === "Complete"
                      ? "lightGreen"
                      : idx === firstPending
                      ? "lightBlue"
                      : "inherit",
                }}
              >
                <Typography variant="body2">
                  {task.userName}:{" "}
                  {task.title
                    ? task.title
                    : task.formName ||
                      taskTypes[taskTypes.findIndex((c) => c.key === task.type)]
                        .value}
                </Typography>
                <Typography variant="body2">Status: {task.status}</Typography>
              </Box>
            </div>
          ))}
        </Box>
      </Collapse>
      <AddActionItemModal
        addModalVisible={openModal}
        setAddModalVisible={setOpenModal}
        formSubmit={formSubmit}
        handleNewTaskAdd={handleNewTaskAdd}
        tasks={tasks}
        form={form}
      />
      <TaskViewEditModal
        form={form}
        addModalVisible={selectedTaskModal}
        setAddModalVisible={setSelectedTaskModal}
        formSubmit={formSubmit}
        handleNewTaskAdd={handleNewTaskAdd}
        tasks={tasks}
        selectedTask={selectedTask}
        handleRemoveDeletedTask={handleRemoveDeletedTask}
      />
    </Box>
  );
}

ActionItemsBox.propTypes = {
  tasks: PropTypes.array.isRequired,
  formSubmit: PropTypes.object.isRequired,
  form: PropTypes.object,
  handleNewTaskAdd: PropTypes.func.isRequired,
  handleRemoveDeletedTask: PropTypes.func.isRequired,
  setTasks: PropTypes.func.isRequired,
  handleSaveReorder: PropTypes.func.isRequired,
};

ActionItemsBox.defaultProps = {
  form: {},
};
