import { useEffect, useState } from "react";

import { questionTypeCodes } from "../../shared/hoc/pagesWeb/incidentReporter/constants";
import { apiSubmitForm } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

function useFormController(form) {
  const [question, setQuestion] = useState({ text: "" });
  const [answer, setAnswer] = useState({
    idxPath: [{ questionIdx: 0 }],
    answerOptions: [],
  });
  // 0 = not submitted, 1 = submitting, 2 = submitted and succeeded
  const [submitting, setSubmitting] = useState(0);
  const [showSummary, setShowSummary] = useState(false);
  const [qidPath, setQidPath] = useState([]);
  const [answerErrors, setAnswerErrors] = useState();

  const [formSubmit, setFormSubmit] = useState({
    formUUID: form.uuid,
    uuid: form.submissionUUID,
    answers: [],
  });

  const addAnswerToFormSubmit = (_answer) => {
    const previouslyAnsweredIdx = formSubmit.answers?.findIndex(
      (x) => x.questionId === _answer.questionId
    );

    console.log({ previouslyAnsweredIdx, _answer });

    if (previouslyAnsweredIdx >= 0) {
      const tempAnswersArr = [...formSubmit.answers];

      tempAnswersArr.splice(previouslyAnsweredIdx, 1, _answer);

      setFormSubmit((prevState) => ({
        ...prevState,
        answers: tempAnswersArr,
      }));
    } else {
      setFormSubmit((prevState) => ({
        ...prevState,
        answers: [...prevState.answers, { ..._answer }],
      }));
    }
  };

  const validateAnswer = () => {
    if (!question.requiresAnswer) {
      setAnswerErrors(false);
      return false;
    }
    if (
      question.typeCode === questionTypeCodes.calendar &&
      question.answer.selectedDate
    ) {
      setAnswerErrors(false);
      return false;
    }
    if (
      (question.typeCode === questionTypeCodes.multiple ||
        question.typeCode === questionTypeCodes.multiple ||
        question.typeCode === questionTypeCodes.dataSelect) &&
      answer.answerOptions?.length
    ) {
      setAnswerErrors(false);
      return false;
    }
    if (
      (question.typeCode === questionTypeCodes.text ||
        question.typeCode === questionTypeCodes.paragraph) &&
      answer.text?.replace(/ /g, "")
    ) {
      setAnswerErrors(false);
      return false;
    }
    if (
      question.typeCode === questionTypeCodes.upload &&
      answer.files?.length
    ) {
      setAnswerErrors(false);
      return false;
    }
    if (
      question.typeCode === questionTypeCodes.formSender &&
      answer.witnesses?.length
    ) {
      setAnswerErrors(false);
      return false;
    }
    setAnswerErrors(true);
    return true;
  };

  const handlePressNext = () => {
    const _answerErrors = validateAnswer();
    if (_answerErrors) return;
    addAnswerToFormSubmit(answer);
    setQidPath((prevState) => [...prevState, question?.uuid]);
    const tempAnswer = { ...answer };
    const answerIdxPath = tempAnswer.idxPath;
    const { nextIdxPath, nextQuestion } = getNextQuestion(
      answerIdxPath,
      form.questions
    );

    if (nextQuestion === undefined) {
      setShowSummary(true);
      return;
    }

    const previousAnswerIdx = formSubmit.answers?.findIndex(
      (x) => x.questionId === nextQuestion.uuid
    );

    if (previousAnswerIdx >= 0) {
      const previouslySubmittedAnswer = formSubmit.answers[previousAnswerIdx];
      setAnswer(previouslySubmittedAnswer);
    } else {
      setAnswer({
        idxPath: nextIdxPath,
        questionId: nextQuestion.uuid,
        submissionUUID: nextQuestion.submissionUUID,
      });
    }

    setQuestion(nextQuestion);
  };

  const getNextQuestion = (answerIdxPath, questionArr) => {
    const mostRecentPath = answerIdxPath[answerIdxPath.length - 1];
    if (answerIdxPath.length < 2) {
      if (mostRecentPath.answerOptions?.length > 0) {
        const parentQuestion = questionArr[answerIdxPath[0].questionIdx];
        if (parentQuestion.answerOptions?.length > 0) {
          const selectedAnswerOptions = answerIdxPath[0].answerOptions;
          for (let i = 0; i < selectedAnswerOptions?.length; i += 1) {
            const questionAOIdx = parentQuestion.answerOptions.findIndex(
              (x) => x.uuid === selectedAnswerOptions[i].key
            );
            if (
              parentQuestion.answerOptions[questionAOIdx].subQuestions?.length >
              0
            ) {
              const questionIdx = 0;
              const nextQuestion =
                parentQuestion.answerOptions[questionAOIdx].subQuestions[
                  questionIdx
                ];
              const remainingParentAnswerOptions =
                answerIdxPath[0].answerOptions.slice(i + 1);
              const nextIdxPath = [
                {
                  ...answerIdxPath[0],
                  answerOptions: remainingParentAnswerOptions,
                },
                {
                  questionAOIdx,
                  questionIdx,
                  remainingParentAnswerOptions,
                },
              ];

              return { nextQuestion, nextIdxPath };
            }
          }
          const questionIdx = mostRecentPath.questionIdx + 1;
          const nextQuestion = questionArr[questionIdx];
          const nextIdxPath = [
            { ...answerIdxPath[0], questionIdx, answerOptions: null },
          ];
          return { nextQuestion, nextIdxPath };
        }
      } else if (mostRecentPath.questionIdx < questionArr.length) {
        const questionIdx = mostRecentPath.questionIdx + 1;
        const nextQuestion = questionArr[questionIdx];

        const nextIdxPath = [{ ...answerIdxPath[0], questionIdx }];
        return { nextQuestion, nextIdxPath };
      } else {
        console.log("get the next question");
      }
    } else {
      const shiftedIdxPath = answerIdxPath.slice(1);
      const _subQuestions =
        questionArr[answerIdxPath[0].questionIdx].answerOptions[
          shiftedIdxPath[0].questionAOIdx
        ].subQuestions;
      let recursiveReturn = getNextQuestion(shiftedIdxPath, _subQuestions);
      if (recursiveReturn.nextQuestion && recursiveReturn.nextIdxPath) {
        recursiveReturn.nextIdxPath.unshift(answerIdxPath[0]);
        return recursiveReturn;
      }
      const cutAnswerIdxPath = answerIdxPath.slice(0, answerIdxPath.length - 1);
      recursiveReturn = getNextQuestion(cutAnswerIdxPath, questionArr);
      if (recursiveReturn.nextQuestion && recursiveReturn.nextIdxPath) {
        return recursiveReturn;
      }
    }

    const nextQuestion = form.questions[0].answerOptions[0].subQuestions[0];
    return {
      answerIdxPath,
      nextQuestion: { ...nextQuestion, text: "I don't know where to go" },
    };
  };

  const getQuestion = (idxPath, questionArr) => {
    if (idxPath.length < 2) {
      return questionArr[idxPath[0].questionIdx];
    }
    const shiftedIdxPath = idxPath.slice(1);

    return getQuestion(
      shiftedIdxPath,
      questionArr[idxPath[0].questionIdx].answerOptions[
        shiftedIdxPath[0].questionAOIdx
      ].subQuestions
    );
  };

  const handlePressBack = () => {
    if (showSummary) {
      setShowSummary(false);
      // return;
    }
    const _qidPath = [...qidPath];
    const lastQuestionId = _qidPath.pop();
    const previousAnswerIdx = formSubmit.answers?.findIndex(
      (x) => x.questionId === lastQuestionId
    );
    const previouslySubmittedAnswer = formSubmit.answers[previousAnswerIdx];
    const _question = getQuestion(
      previouslySubmittedAnswer.idxPath,
      form.questions
    );

    setQidPath(_qidPath);
    setAnswer(previouslySubmittedAnswer);
    setQuestion(_question);
  };

  const handleAnswerChange = (_answer) => {
    if (
      question.typeCode === questionTypeCodes.multiple ||
      question.typeCode === questionTypeCodes.dataSelect
    ) {
      const newlyAdded = _answer.pop();
      const existingIdx = _answer?.findIndex((x) => x.key === newlyAdded?.key);
      if (existingIdx > -1) {
        _answer.splice(existingIdx, 1);
      } else if (newlyAdded) {
        _answer.push(newlyAdded);
      }
      const tempIdxPath = [...answer.idxPath];
      tempIdxPath[tempIdxPath.length - 1].answerOptions = _answer;
      setAnswer((prevState) => ({
        ...prevState,
        answerOptions: _answer,
        idxPath: tempIdxPath,
      }));
    } else if (question.typeCode === questionTypeCodes.upload) {
      setAnswer((prevState) => ({
        ...prevState,
        files: _answer,
      }));
    } else if (question.typeCode === questionTypeCodes.formSender) {
      setAnswer((prevState) => ({
        ...prevState,
        witnesses: prevState.witnesses
          ? [...prevState.witnesses, _answer]
          : [_answer],
      }));
    } else if (
      question.typeCode === questionTypeCodes.text ||
      question.typeCode === questionTypeCodes.paragraph
    ) {
      setAnswer((prevState) => ({
        ...prevState,
        text: _answer,
      }));
    } else if (question.typeCode === questionTypeCodes.calendar) {
      setAnswer((prevState) => ({
        ...prevState,
        selectedDate: new Date(_answer),
      }));
    }
  };

  const handleSubmitReport = async (
    submissionID,
    answerSubmissionUUID,
    taskID
  ) => {
    setSubmitting(1);
    const formData = new FormData();
    formData.append("formUUID", form.uuid);
    formData.append("companyUUID", form.companyID);

    if (submissionID) {
      formData.append("uuid", submissionID);
    }

    if (taskID) {
      formData.append("taskUUID", taskID);
    }

    if (answerSubmissionUUID) {
      formData.append("floatingAnswerUUID", answerSubmissionUUID);
    }

    const formSubmission = { ...formSubmit };
    for (let i = 0; i < formSubmit.answers.length; i += 1) {
      formData.append(
        `answers[${i}][questionUUID]`,
        formSubmit.answers[i].questionId
      );
      if (formSubmit.answers[i].submissionUUID) {
        formData.append(
          `answers[${i}][uuid]`,
          formSubmit.answers[i].submissionUUID
        );
      }
      if (formSubmit.answers[i].selectedDate) {
        formData.append(
          `answers[${i}][selectedDate]`,
          formSubmit.answers[i].selectedDate?.toISOString()
        );
      }
      if (formSubmit.answers[i].text) {
        formData.append(`answers[${i}][text]`, formSubmit.answers[i].text);
      }

      formSubmit.answers[i].answerOptions?.forEach((_answerOption, idx) => {
        formData.append(
          `answers[${i}][answerOptionIDs][${idx}]`,
          _answerOption.key
        );
      });
      formSubmit.answers[i].witnesses?.forEach((witnessEmail, idx) => {
        formData.append(
          `answers[${i}][formSendUserEmails][${idx}]`,
          witnessEmail
        );
      });

      if (formSubmit.answers[i].files) {
        const _files = [];
        for (let x = 0; x < formSubmission.answers[i].files.length; x += 1) {
          const reader = new FileReader();

          // eslint-disable-next-line no-await-in-loop
          const fileContent = await new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(formSubmission.answers[i].files[x]);
          });

          formData.append(`answers[${i}][Base64Files][${x}]`, fileContent);
        }
        formSubmission.answers[i].files = _files;
      }
    }

    const response = await apiSubmitForm(formData);

    if (response === "Success") {
      setSubmitting(2);
    }
  };

  useEffect(() => {
    if (form?.questions && form?.questions.length > 0) {
      setQuestion(form.questions[answer.idxPath[0].questionIdx]);
      setAnswer((prevState) => ({
        ...prevState,
        questionId: form.questions[answer.idxPath[0].questionIdx].uuid,
      }));
      setFormSubmit({
        formUUID: form.uuid,
        uuid: form.submissionUUID,
        answers: [],
      });
    }
  }, [form]);

  return {
    handlePressNext,
    handlePressBack,
    handleAnswerChange,
    handleSubmitReport,
    question,
    answer,
    showSummary,
    formSubmit,
    answerErrors,
    submitting,
  };
}

export default useFormController;
