import PropTypes from "prop-types";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

import LSModal from "./LSModal";
import {
  setQuestionItemsList,
  updateAnswerOptionQuestion,
} from "../../../shared/components/Sliders/utils";
import { responseCodes } from "../../constants/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import LSText, { textVariant } from "../text/LSText";

export default function AddContingentQuestionModal({
  addContingentModalVisible,
  setAddContingentModalVisible,
  selectedQuestionAnswer,
  questionsArr,
  handleDupQuestion,
}) {
  const [questionDup, setQuestionDup] = useState({});
  const sortedQuestionArr = [...questionsArr].sort((a, b) =>
    a.text > b.text ? 1 : -1
  );

  const handleDupQuestionAnswer = async () => {
    const dupObject = {
      uuid: questionDup.uuid,
      answerOptionUUID:
        selectedQuestionAnswer.answerOptionUUID || selectedQuestionAnswer.uuid,
      order:
        selectedQuestionAnswer?.subQuestions?.length ||
        selectedQuestionAnswer.order,
    };
    const response = await updateAnswerOptionQuestion(dupObject);
    if (response.status === responseCodes.Created) {
      setAddContingentModalVisible(false);
      handleDupQuestion({
        ...dupObject,
        idxObject: {
          ...selectedQuestionAnswer.idxObject,
          subIdx: dupObject.order,
        },
      });
    }
  };

  return (
    <LSModal
      visible={addContingentModalVisible}
      setVisible={setAddContingentModalVisible}
    >
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <LSText
          text="Duplicate Existing Question"
          variant={textVariant.h4}
          customStyles={{ marginBottom: 16 }}
        />
        <LSText
          text="What question would you like to come after this question?"
          variant={textVariant.caption}
          customStyles={{ marginBottom: 16 }}
        />
        <View style={{ width: 280 }}>
          <LSDropdownPicker
            _items={setQuestionItemsList(sortedQuestionArr)}
            setValue={setQuestionDup}
            name="uuid"
          />
        </View>
        <View style={styles.buttonsContainer}>
          <LSButton
            onPress={() => setAddContingentModalVisible(false)}
            text="Cancel"
            variant={buttonVariants.secondary}
            customStyles={{ marginRight: 16, flex: 1 }}
          />
          <LSButton
            onPress={handleDupQuestionAnswer}
            variant={buttonVariants.primary}
            text="Add"
            customStyles={{ flex: 1 }}
          />
        </View>
      </Card>
    </LSModal>
  );
}

AddContingentQuestionModal.propTypes = {
  setAddContingentModalVisible: PropTypes.func.isRequired,
  handleDupQuestion: PropTypes.func.isRequired,
  addContingentModalVisible: PropTypes.bool.isRequired,
  selectedQuestionAnswer: PropTypes.any.isRequired,
  questionsArr: PropTypes.array.isRequired,
};

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
});
