import {
  Box,
  Collapse,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import LSModal from "./LSModal";
import {
  completionTimelineOptions,
  promptOptions,
  taskTypes,
} from "../../hooks/constants";
import useAddActionItems from "../../hooks/useAddActionItems";
import useForms from "../../hooks/useForms";
import useUsers from "../../hooks/useUsers";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import LSInput from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

export default function AddActionItemModal({
  addModalVisible,
  setAddModalVisible,
  formSubmit,
  handleNewTaskAdd,
  tasks,
  form,
}) {
  const {
    task,
    confirm,
    taskErrors,
    descMaxChar,
    setConfirm,
    setTask,
    handleSave,
  } = useAddActionItems(
    formSubmit?.uuid,
    handleNewTaskAdd,
    tasks,
    setAddModalVisible,
    null,
    null,
    form?.uuid
  );
  const { users } = useUsers();
  const { forms } = useForms();

  useEffect(() => {
    console.log({ task, formSubmit });
  }, [task, formSubmit]);

  const mappedUsers = users
    ?.sort((a, b) => a.lastName - b.lastName)
    .map((user) => ({
      key: user.uuid,
      value: `${user.lastName}, ${user.firstName}`,
    }));

  const mappedForms = forms
    ?.sort((a, b) => a.lastName - b.lastName)
    .map((_form) => ({
      key: _form.uuid,
      value: _form.name,
    }));

  return (
    <LSModal visible={addModalVisible} setVisible={setAddModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginBottom: 32,
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <LSText
              text={confirm ? "Are You Sure?" : "Add Task"}
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            {confirm ? (
              <LSText
                text="Are you sure you want to create a new task?"
                variant={textVariant.h6}
                customStyles={{ marginBottom: 8 }}
              />
            ) : (
              <>
                <LSText
                  text="User to complete task"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8 }}
                />
                <LSDropdownPicker
                  _items={mappedUsers}
                  name="userUUID"
                  answerKey="userUUID"
                  answer={task}
                  setValue={setTask}
                  error={taskErrors.userUUID}
                />
                <LSText
                  text="Email Prompt"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8, marginTop: 16 }}
                />
                <LSDropdownPicker
                  _items={promptOptions}
                  name="prompt"
                  answerKey="prompt"
                  answer={task}
                  setValue={setTask}
                  error={taskErrors.prompt}
                />
                <LSText
                  text="Task Type"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8, marginTop: 16 }}
                />
                <LSDropdownPicker
                  _items={taskTypes}
                  name="type"
                  answerKey="type"
                  answer={task}
                  setValue={setTask}
                  error={taskErrors.type}
                />
                <Collapse
                  sx={{ marginTop: task.type === 1 ? 2 : 0 }}
                  in={task.type === 1}
                >
                  <LSText
                    text="Form to be completed"
                    variant={textVariant.overline}
                    customStyles={{ marginBottom: "8px" }}
                  />
                  <LSDropdownPicker
                    _items={mappedForms}
                    name="taskFormUUID"
                    answerKey="taskFormUUID"
                    answer={task}
                    setValue={setTask}
                    error={taskErrors.taskFormUUID}
                  />
                </Collapse>
                <Collapse in={task.type === 2 || task.type === 3}>
                  <LSText
                    text="Title"
                    variant={textVariant.overline}
                    customStyles={{ marginTop: 16 }}
                  />
                  <LSInput
                    setter={setTask}
                    name="title"
                    object={task}
                    customStyles={{ backgroundColor: "white" }}
                    errorObj={taskErrors}
                  />
                </Collapse>
                <LSText
                  text="Completion Timeline"
                  variant={textVariant.overline}
                  customStyles={{ marginBottom: 8, marginTop: 16 }}
                />
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  row
                  value={task.completionTime}
                  onChange={(e) =>
                    setTask((prevState) => ({
                      ...prevState,
                      completionTime: e.target.value,
                    }))
                  }
                >
                  {completionTimelineOptions.map((option) => (
                    <FormControlLabel
                      key={`${option.value}-${option.key}`}
                      value={option.key}
                      control={<Radio />}
                      label={option.value}
                      componentsProps={{ typography: { variant: "subtitle2" } }}
                    />
                  ))}
                </RadioGroup>
                <LSText
                  text="Description"
                  variant={textVariant.overline}
                  customStyles={{ marginTop: 8 }}
                />
                <LSInput
                  multiline
                  numberOfLines={5}
                  setter={setTask}
                  name="description"
                  object={task}
                  customStyles={{ backgroundColor: "white" }}
                  errorObj={taskErrors}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="caption">
                    {task?.description?.length || 0} / {descMaxChar}
                  </Typography>
                </Box>
              </>
            )}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LSButton
                onPress={() => {
                  if (confirm) {
                    setConfirm(false);
                  } else {
                    setAddModalVisible(false);
                  }
                }}
                text={confirm ? "Back" : "Cancel"}
                variant={buttonVariants.secondary}
                customStyles={{ marginRight: 16, flex: 1 }}
              />
              <LSButton
                onPress={handleSave}
                variant={buttonVariants.primary}
                text={confirm ? "Confirm" : "Save"}
                customStyles={{ flex: 1 }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

AddActionItemModal.propTypes = {
  addModalVisible: PropTypes.bool.isRequired,
  setAddModalVisible: PropTypes.func.isRequired,
  formSubmit: PropTypes.string,
  handleNewTaskAdd: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  form: PropTypes.object,
};

AddActionItemModal.defaultProps = {
  formSubmit: null,
  form: null,
};
