import { useState, useEffect } from "react";

import {
  ADD_LOCATION,
  GET_LOCATIONS,
  UPDATE_LOCATIONS,
} from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPostAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

const newLocationObject = {
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
};

const locationErrorsObject = {
  name: false,
  address: false,
  city: false,
  state: false,
  zip: false,
};

function useLocations(setAddModalVisible) {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationErrors, setLocationErrors] = useState(locationErrorsObject);
  const [newLocation, setNewLocation] = useState(newLocationObject);
  const [editLocation, setEditLocation] = useState(null);
  const [editLocationErrors, setEditLocationErrors] = useState({});

  const getLocations = async () => {
    const results = await apiGetAuth(GET_LOCATIONS);
    console.log({ results });
    setLocations(results);
    setLoading(false);
  };

  const validateLocation = () => {
    let errors = false;

    const keys = Object.keys(newLocation);
    keys.forEach((key) => {
      if (!newLocation[key]) {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        errors = true;
      } else {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    return errors;
  };

  const validateLocationEdit = () => {
    let errors = false;

    if (!editLocation.name?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    if (!editLocation.address?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        address: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        address: false,
      }));
    }
    if (!editLocation.city?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        city: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        city: false,
      }));
    }
    if (!editLocation.state?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        state: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        state: false,
      }));
    }
    if (!editLocation.zip?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        zip: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        zip: false,
      }));
    }

    return errors;
  };

  const handleGenerateLocationPress = async () => {
    const errors = validateLocation();

    if (errors) return;

    const response = await apiPostAuth(ADD_LOCATION, newLocation);
    if (response.name) {
      setLocations((prevState) => [response, ...prevState]);
      setNewLocation(newLocationObject);
      setAddModalVisible(false);
    }
  };

  const handleEditLocationSubmit = async () => {
    const errors = validateLocationEdit();

    console.log({ errors });

    if (errors) return;

    console.log("saving edit", editLocation);
    let base64 = "";
    if (editLocation?.files.length > 0) {
      const reader = new FileReader();
      base64 = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(editLocation.files[0]);
      });

      base64 = `${base64},${editLocation?.files[0].name}`;

      console.log({ base64 });
    }

    const response = await apiPostAuth(UPDATE_LOCATIONS, {
      ...editLocation,
      base64,
    });

    if (response.uuid) {
      const idx = locations.findIndex((c) => c.uuid === response.uuid);

      const tempArr = [...locations];
      tempArr[idx] = response;
      setLocations(tempArr);
      setAddModalVisible(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    console.log({ editLocation, editLocationErrors });
  }, [editLocation, editLocationErrors]);

  return {
    locations,
    loading,
    locationErrors,
    editLocationErrors,
    newLocation,
    editLocation,
    setNewLocation,
    setEditLocation,
    handleGenerateLocationPress,
    handleEditLocationSubmit,
  };
}

export default useLocations;
