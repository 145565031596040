import {
  Box,
  Typography,
  ListItemText,
  Checkbox,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import LSModal from "./LSModal";
import { permissionsList } from "../../pages/constants";
import { setPermissions } from "../../redux/permissions";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";

export default function ImpersonationModal({ visible, setVisible }) {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions);
  const [impersonationPermissions, setImpersonationPermissions] = useState();

  const handleChangeImpersonationPermissions = (name) => {
    setImpersonationPermissions((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  useEffect(() => {
    setImpersonationPermissions(permissions);
  }, [permissions]);

  const showPermissions = useCallback(() => (
    <Box sx={{ height: "200px", overflow: "scroll" }}>
      {permissionsList?.map((permissionGroup) => (
        <>
          <ListSubheader
            key={`impersonation-cat-${permissionGroup.category}`}
            sx={{
              color: "black",
              fontWeight: "bold",
              borderBottom: 1,
            }}
          >
            {permissionGroup.category}
          </ListSubheader>
          {permissionGroup.permissions.map((permission) => (
            <MenuItem
              key={`impersonation-${permission.name}`}
              value={permission.name}
              sx={{ marginLeft: -3 }}
              onClick={() =>
                handleChangeImpersonationPermissions(permission.name)
              }
            >
              <Checkbox checked={impersonationPermissions?.[permission.name]} />
              <ListItemText primary={`${permission.permissionName}`} />
            </MenuItem>
          ))}
        </>
      ))}
    </Box>
  ));

  return (
    <LSModal visible={visible} setVisible={setVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box sx={{ width: "100%", marginBottom: "32px" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderBottomColor: "lightGray",
              marginY: 0.5,
              paddingY: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, marginTop: "8px" }}
              >
                Impersonate Permissions
              </Typography>
            </Box>
            {showPermissions()}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LSButton
              text="Cancel"
              variant={buttonVariants.secondary}
              customStyles={{}}
              onPress={() => setVisible(false)}
            />
            <LSButton
              text="Test"
              variant={buttonVariants.primary}
              customStyles={{ flex: 1, marginHorizontal: 12 }}
              onPress={() => {
                setVisible(false);
                dispatch(setPermissions(impersonationPermissions));
              }}
            />
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

ImpersonationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
