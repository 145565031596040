import { useState } from "react";

import { apiSubmitCompletedTask } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useCompleteTask(_task) {
  const [taskEdit, setTask] = useState(_task);

  const updateTask = (e) => {
    console.log(e);
    if (e?.target?.value && e?.target?.name) {
      const { name, value } = e.target;
      console.log({ name, value });
      setTask((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setTask((prevState) => ({
        ...prevState,
        files: e,
      }));
    }
  };

  const handleSaveChanges = async (callBack) => {
    console.log({ taskEdit });
    const formData = new FormData();
    formData.append("uuid", taskEdit.uuid);
    formData.append("comments", taskEdit.comments);

    if (taskEdit.files) {
      const _files = [];
      for (let x = 0; x < taskEdit.files.length; x += 1) {
        const reader = new FileReader();

        // eslint-disable-next-line no-await-in-loop, no-loop-func
        const fileContent = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(taskEdit.files[x]);
        });

        formData.append(
          `[Base64Files][${x}]`,
          `${fileContent},${taskEdit.files[x].name}`
        );
      }
      taskEdit.files = _files;
    }

    const response = await apiSubmitCompletedTask(formData);

    if (response === "Success") {
      callBack();
    }
  };

  return { taskEdit, updateTask, handleSaveChanges };
}
