import { Box, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import LSModal from "./LSModal";
import { taskTypes } from "../../hooks/constants";
import useCompleteTask from "../../hooks/useCompleteTask";
import { urlRoutes } from "../../pages/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import InputFileUpload from "../formInputs/InputFileUpload";
import LSText, { textVariant } from "../text/LSText";

export default function CompleteTaskModal({
  modalVisible,
  setModalVisible,
  task,
  setCounter,
  handleCompleteForm,
}) {
  const navigate = useNavigate();
  const { taskEdit, updateTask, handleSaveChanges } = useCompleteTask(task);

  const handleNavigateTaskForm = () => {
    navigate(
      `${urlRoutes.form}?form=${task.taskFormUUID}&taskUUID=${task.uuid}`
    );
  };

  const getTitle = () => {
    if (task.type === 4) {
      return "Review and Close";
    }
    if (task.type === 3 || task.type === 2) {
      return task.title;
    }
    return task.formName;
  };

  return (
    <LSModal visible={modalVisible} setVisible={setModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginBottom: 32,
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <LSText
              text="Complete Tasks"
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
          </Box>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "55px", marginTop: "-3px" }}>
                <LSText
                  text="Title:"
                  variant={textVariant.H5}
                  customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                />
              </Box>
              <LSText text={getTitle()} variant={textVariant.H5} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "55px", marginTop: "-3px" }}>
                <LSText
                  text="Desc.:"
                  variant={textVariant.H5}
                  customStyles={{ fontWeight: "bold", marginRight: "8px" }}
                />
              </Box>
              <LSText text={task.description} variant={textVariant.H5} />
            </Box>
            {(task.type === taskTypes[1].key ||
              task.type === taskTypes[2].key ||
              task.type === taskTypes[3].key) && (
              <>
                <TextField
                  id="filled-textarea"
                  label="Comments"
                  rows={6}
                  multiline
                  variant="filled"
                  name="comments"
                  value={taskEdit.comments}
                  onChange={updateTask}
                  sx={{ width: "100%", marginTop: 3 }}
                />
                <Box sx={{ width: "100%", marginTop: 2 }}>
                  <InputFileUpload
                    handleUploadFile={updateTask}
                    answer={taskEdit}
                    minDisplay
                  />
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <LSButton
                onPress={() => setModalVisible(false)}
                text="Cancel"
                variant={buttonVariants.secondary}
                customStyles={{ marginRight: 16, flex: 1 }}
              />
              {task.type === taskTypes[0].key && (
                <LSButton
                  onPress={handleNavigateTaskForm}
                  variant={buttonVariants.primary}
                  text="Go To Form"
                  customStyles={{ flex: 1 }}
                />
              )}
              {(task.type === taskTypes[1].key ||
                task.type === taskTypes[2].key ||
                task.type === taskTypes[3].key) && (
                <LSButton
                  onPress={() =>
                    handleSaveChanges(() => {
                      setModalVisible(false);
                      setCounter((prevState) => prevState + 1);
                      handleCompleteForm();
                    })
                  }
                  variant={buttonVariants.primary}
                  text="Complete"
                  customStyles={{ flex: 1 }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </LSModal>
  );
}

CompleteTaskModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  setCounter: PropTypes.func.isRequired,
  handleCompleteForm: PropTypes.func.isRequired,
};

CompleteTaskModal.defaultProps = {};
