import { Box } from "@mui/material";
import React from "react";

import { homeApps } from "./constants";
import HomeAppCard from "../components/cards/HomeAppCard";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";

const pageTitle = "Home";

export default function Home() {
  return (
    <PageLayout>
      <LSText variant={textVariant.h1} text={pageTitle} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          minHeight: "85vh",
          flexWrap: "wrap",
          maxWidth: "100%",
        }}
      >
        {homeApps?.map((app) => (
          <HomeAppCard key={app.id} app={app} />
        ))}
      </Box>
    </PageLayout>
  );
}
