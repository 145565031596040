import { FontAwesome } from "@expo/vector-icons";
import { Button, Paper, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { fontColorDark, green } from "../../../theme/colors";
import PageLayout from "../../components/layouts/PageLayout";
import AddPositionModal from "../../components/modals/AddPositionModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import usePositions from "../../hooks/usePositions";
import { pageAppTypes } from "../constants";

const pageTitle = "Positions";
const titleWidth = "100px";

export default function CompanyPositions() {
  const [addModalVisible, setAddModalVisible] = useState(false);

  const {
    positions,
    newPosition,
    newPositionErrors,
    editPositionErrors,
    editPosition,
    handleSetEditPosition,
    setNewPosition,
    handleValidatePosition,
    handleValidateEditPosition,
    handleSubmit,
    handleUpdate,
    setEditPosition,
    handleSetPositionPermissions,
    handleUpdatePositionPermissions,
  } = usePositions(setAddModalVisible);
  const permissions = useSelector((state) => state.permissions);
  console.log({ permissions });

  return (
    <PageLayout pageAppType={pageAppTypes.users}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorDark}
        />
        <Button
          sx={{
            borderRadius: "50%",
            padding: 2,
            height: "60px",
            width: "40px",
            backgroundColor: green[400],
            "&:hover": {
              backgroundColor: green[100],
              color: "#3c52b2",
            },
          }}
          onClick={() => {
            setEditPosition(null);
            setAddModalVisible(true);
          }}
        >
          <FontAwesome name="plus" size={25} color={fontColorDark} />
        </Button>
      </Box>
      {false ? (
        <LSSpinner />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {positions.map((position, idx) => (
            <Paper
              key={`${position.uuid}-position-card`}
              elevation={4}
              sx={{
                padding: 2,
                margin: 1,
                borderRadius: 1,
                width: "350px",
              }}
              onClick={() => handleSetEditPosition(idx)}
            >
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold", width: titleWidth }}>
                  Name:{" "}
                </Typography>
                {position.name}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold", width: titleWidth }}>
                  Manager:{" "}
                </Typography>
                {position.manager}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold", width: titleWidth }}>
                  Type:{" "}
                </Typography>
                {position.type}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography sx={{ fontWeight: "bold", width: titleWidth }}>
                  User Count:{" "}
                </Typography>
                {position.usersCount}
              </Typography>
            </Paper>
          ))}
        </Box>
      )}
      <AddPositionModal
        positions={positions}
        newPositionErrors={
          editPosition ? editPositionErrors : newPositionErrors
        }
        setNewPosition={editPosition ? setEditPosition : setNewPosition}
        newPosition={editPosition || newPosition}
        addModalVisible={addModalVisible}
        setAddModalVisible={setAddModalVisible}
        handleValidatePosition={
          editPosition ? handleValidateEditPosition : handleValidatePosition
        }
        handleSubmit={editPosition ? handleUpdate : handleSubmit}
        handleSetPositionPermissions={
          editPosition
            ? handleUpdatePositionPermissions
            : handleSetPositionPermissions
        }
        editPosition={editPosition}
      />
    </PageLayout>
  );
}
