import {
  Typography,
  Box,
  ListItemText,
  Checkbox,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import LSModal from "./LSModal";
import { fontColorLight } from "../../../theme/colors";
import { permissionsList } from "../../pages/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import LSInput, { inputVariants } from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

const positionTypeOptions = [
  {
    key: 1,
    value: "Corporate",
  },
  {
    key: 2,
    value: "Location",
  },
];

export default function AddPositionModal({
  positions,
  setNewPosition,
  editPosition,
  newPosition,
  newPositionErrors,
  addModalVisible,
  setAddModalVisible,
  handleValidatePosition,
  handleSetPositionPermissions,
  handleSubmit,
}) {
  const [addPositionStep, setAddPositionStep] = useState(1);

  const handlePressNext = () => {
    if (addPositionStep === 1) {
      const errors = handleValidatePosition();
      if (!errors) {
        setAddPositionStep(2);
      }
    } else {
      console.log("Hello");
      handleSubmit(() => setAddPositionStep(1));
    }
  };

  const handlePressBack = () =>
    addPositionStep === 1
      ? setAddModalVisible(false)
      : setAddPositionStep((prevState) => prevState - 1);

  const getAddUserFormContent = () => {
    if (addPositionStep === 1) {
      return (
        <>
          <LSInput
            variant={inputVariants.primary}
            setter={setNewPosition}
            object={newPosition}
            name="name"
            placeholder="Name"
            errorObj={newPositionErrors}
            customStyles={styles.inputStyles}
          />
          <Box sx={{ marginBottom: 1 }}>
            <LSDropdownPicker
              _items={positionTypeOptions}
              name="type"
              answerKey="type"
              answer={newPosition}
              setValue={setNewPosition}
              error={newPositionErrors.type}
              placeholder="Position Type"
            />
          </Box>
          <LSDropdownPicker
            _items={positions.map((p) => ({ key: p.uuid, value: p.name }))}
            name="managerUUID"
            answerKey="managerUUID"
            answer={newPosition}
            setValue={setNewPosition}
            error={newPositionErrors.manager}
            placeholder="Manager"
          />
        </>
      );
    }
    if (addPositionStep === 2) {
      return (
        <Box>
          <Typography variant="h6">Default Permissions</Typography>
          <Box sx={{ height: "340px", overflow: "scroll" }}>
            {permissionsList?.map((permissionGroup, _idx) => (
              <>
                <ListSubheader
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${permissionGroup.category}-${_idx}`}
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    borderBottom: 1,
                  }}
                >
                  {permissionGroup.category}
                </ListSubheader>
                {permissionGroup.permissions.map((permission, idx) => (
                  <MenuItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${permission.name}-idx-${idx}`}
                    sx={{ marginLeft: -3 }}
                    onClick={() =>
                      handleSetPositionPermissions(permission.name)
                    }
                  >
                    <Checkbox
                      checked={
                        newPosition?.defaultPermission?.[permission.name]
                      }
                    />
                    <ListItemText primary={`${permission.permissionName}`} />
                  </MenuItem>
                ))}
              </>
            ))}
          </Box>
        </Box>
      );
    }
    return "";
  };

  useEffect(() => {
    setAddPositionStep(1);
  }, [addModalVisible]);

  return (
    <LSModal visible={addModalVisible} setVisible={setAddModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <View style={{ width: "100%", marginBottom: 32 }}>
          <LSText
            text={editPosition ? "Edit Position" : "Add New Position"}
            variant={textVariant.h2}
            customStyles={{ marginBottom: 16, textAlign: "center" }}
          />
          {getAddUserFormContent()}
        </View>
        <View style={styles.buttonsContainer}>
          <LSButton
            onPress={handlePressBack}
            text={addPositionStep === 1 ? "Cancel" : "Back"}
            variant={buttonVariants.secondary}
            customStyles={{ marginRight: 16, flex: 1 }}
          />
          <LSButton
            onPress={handlePressNext}
            variant={buttonVariants.primary}
            text={addPositionStep === 2 ? "Submit" : "Next"}
            customStyles={{ flex: 1 }}
          />
        </View>
      </Card>
    </LSModal>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
  submissionContainer: {
    borderBottomColor: fontColorLight,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});

AddPositionModal.propTypes = {
  positions: PropTypes.array.isRequired,
  addModalVisible: PropTypes.bool.isRequired,
  setAddModalVisible: PropTypes.func.isRequired,
  setNewPosition: PropTypes.func.isRequired,
  handleValidatePosition: PropTypes.func.isRequired,
  handleSetPositionPermissions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  newPosition: PropTypes.object.isRequired,
  newPositionErrors: PropTypes.object.isRequired,
  editPosition: PropTypes.object,
};

AddPositionModal.defaultProps = {
  editPosition: null,
};
