import { Paper, Box } from "@mui/material";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSearchParams } from "react-router-dom";

import LSLogo from "../../../assets/StackedLogo.svg";
import { localStorageKeys } from "../../../context/constants";
import { ACTIVATE_COMPANY_USER } from "../../../shared/apiUrls";
import { fontColorLight } from "../../../theme/colors";
import { isWeb } from "../../../utils/environments";
import LSButton from "../../components/buttons/LSButton";
import LSInput from "../../components/formInputs/LSInput";
import PageLayout from "../../components/layouts/PageLayout";
import LSText, { textVariant } from "../../components/text/LSText";
import { urlRoutes } from "../constants";

const welcomeText = "Welcome to LinkStep";
const loginTest = "Please set a password";
const tokenExpiredText = "The Token has already expired or no longer exists.";

const emptyLogin = {
  password: "",
  confirmPassword: "",
};

const loginError = {
  password: false,
  confirmPassword: false,
};

export default function SetPassword() {
  const [user, setUser] = useState(emptyLogin);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("tc");
  const [errorObj, setErrorObj] = useState(loginError);
  const [loginErrors, setLoginErrors] = useState("");
  const [tokenErrors, setTokenErrors] = useState(false);

  const validateSignupData = () => {
    let errors = false;
    if (user.password.length < 8) {
      setErrorObj((prevState) => ({
        ...prevState,
        password: true,
      }));
      setLoginErrors("Password must be 8 characters or more.");
      errors = true;
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        password: false,
      }));
    }
    if (user.confirmPassword !== user.password) {
      setErrorObj((prevState) => ({
        ...prevState,
        confirmPassword: true,
      }));
      setLoginErrors("Passwords don't match");
      errors = true;
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        confirmPassword: false,
      }));
    }
    return errors;
  };

  const handleLogin = async () => {
    const errors = validateSignupData();
    if (errors) return;

    const body = {
      ...user,
      code,
    };
    const response = await axios
      .post(ACTIVATE_COMPANY_USER, body)
      .catch((e) => {
        const { data } = e.response;
        if (data === "Token not found.") {
          setTokenErrors(true);
        }
      });
    const { accessToken, refreshToken, registerUser } = response.data;

    await AsyncStorage.setItem(localStorageKeys.accessTokenKey, accessToken);
    await AsyncStorage.setItem(localStorageKeys.refreshToken, refreshToken);
    await AsyncStorage.setItem(
      localStorageKeys.user,
      JSON.stringify(registerUser)
    );

    if (isWeb && registerUser) {
      window.location.href = urlRoutes.home;
    } else {
      //   navigation.navigate("home");
    }
  };

  return (
    <PageLayout center>
      {tokenErrors ? (
        <LSText
          text={tokenExpiredText}
          color={fontColorLight}
          variant={textVariant.h2}
          customStyles={{ textAlign: "center" }}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            minHeight: "85vh",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={2}
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              width: "330px",
            }}
          >
            <img src={LSLogo} alt="LinkStep Logo" />
            <View style={{ marginBottom: 16, marginTop: 16, width: "100%" }}>
              <LSText text={welcomeText} variant={textVariant.h2} />
              <LSText text={loginTest} variant={textVariant.h4} />
              <LSText
                text={loginErrors}
                variant={textVariant.error}
                color="red"
              />
            </View>
            <View style={style.inputContainers}>
              <LSInput
                placeholder="Password"
                name="password"
                object={user}
                errorObj={errorObj}
                setter={setUser}
                password
                customStyles={{ width: 280 }}
              />
            </View>
            <View style={style.inputContainers}>
              <LSInput
                placeholder="Confirm Password"
                name="confirmPassword"
                object={user}
                errorObj={errorObj}
                setter={setUser}
                password
                customStyles={{ width: 280 }}
              />
            </View>
            <View style={style.buttonContainers}>
              <LSButton
                text="Login"
                onPress={handleLogin}
                customStyles={{ width: "100%" }}
              />
            </View>
          </Paper>
        </Box>
      )}
    </PageLayout>
  );
}

const style = StyleSheet.create({
  inputContainers: {
    width: "100%",
    flexDirection: "row",
  },
  buttonContainers: {
    width: "100%",
    marginBottom: 8,
    flexDirection: "row",
  },
});
