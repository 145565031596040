import { useState, useEffect } from "react";

import {
  CREATE_NEW_TASK,
  DELETE_SUBMISSION_TASKS,
  EDIT_TASK,
} from "../../shared/apiUrls";
import {
  apiPostAuth,
  apiPatchAuth,
  apiDeleteAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

const descMaxChar = 150;

const emptyTaskValues = {
  userUUID: "",
  description: "",
  type: 0,
  completionTime: 1,
  prompt: undefined,
  taskFormUUID: null,
  title: null,
};

const defaultTaskErrorValues = {
  userUUID: false,
  description: false,
  type: false,
  completionTime: false,
  prompt: false,
  taskFormUUID: false,
  title: false,
};

export default function useAddActionItems(
  formSubmitUUID,
  handleNewTaskAdd,
  tasks,
  setAddModalVisible,
  selectedTask,
  handleRemoveDeletedTask,
  formUUID
) {
  const [task, setTask] = useState(emptyTaskValues);
  const [taskErrors, setTaskErrors] = useState(defaultTaskErrorValues);
  const [confirm, setConfirm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleSave = async () => {
    if (validate()) return;
    if (!confirm) {
      setConfirm(true);
    } else {
      if (task.uuid) {
        const response = await apiPatchAuth(EDIT_TASK, task);
        handleNewTaskAdd(response);
      } else {
        const response = await apiPostAuth(CREATE_NEW_TASK, {
          ...task,
          formSubmitUUID,
          defaultFormUUID: formUUID,
          order: tasks.length + 1,
        });
        handleNewTaskAdd(response);
      }
      setAddModalVisible(false);
      setConfirm(false);
      setTask(emptyTaskValues);
    }
  };

  const handleDelete = async () => {
    if (confirmDelete) {
      const response = await apiDeleteAuth(
        `${DELETE_SUBMISSION_TASKS}/${task.uuid}`
      );
      console.log(response);
      handleRemoveDeletedTask(task);
      setConfirmDelete(false);
      setAddModalVisible();
    } else {
      setConfirmDelete(true);
    }
  };

  const validate = () => {
    let errors = false;

    if (task.userUUID) {
      setTaskErrors((prevState) => ({
        ...prevState,
        userUUID: false,
      }));
    } else {
      errors = true;
      setTaskErrors((prevState) => ({
        ...prevState,
        userUUID: true,
      }));
    }
    if (task.prompt !== undefined) {
      setTaskErrors((prevState) => ({
        ...prevState,
        prompt: false,
      }));
    } else {
      errors = true;
      setTaskErrors((prevState) => ({
        ...prevState,
        prompt: true,
      }));
    }
    if (task.type > 0) {
      setTaskErrors((prevState) => ({
        ...prevState,
        type: false,
      }));
    } else {
      errors = true;
      setTaskErrors((prevState) => ({
        ...prevState,
        type: true,
      }));
    }
    if (task.description?.length > 0 && task.description.length < descMaxChar) {
      setTaskErrors((prevState) => ({
        ...prevState,
        description: false,
      }));
    } else {
      errors = true;
      setTaskErrors((prevState) => ({
        ...prevState,
        description: true,
      }));
    }
    if (task.type === 1 && !task.taskFormUUID) {
      errors = true;
      setTaskErrors((prevState) => ({
        ...prevState,
        taskFormUUID: true,
      }));
    } else {
      setTaskErrors((prevState) => ({
        ...prevState,
        taskFormUUID: false,
      }));
    }
    if ((task.type === 2 || task.type === 3) && !task.title) {
      errors = true;
      setTaskErrors((prevState) => ({
        ...prevState,
        title: true,
      }));
    } else {
      setTaskErrors((prevState) => ({
        ...prevState,
        title: false,
      }));
    }
    console.log({ errors });
    return errors;
  };

  useEffect(() => {
    if (selectedTask) {
      console.log("The task is here", task);
      setTask(selectedTask);
    }
  }, [selectedTask]);

  return {
    task,
    confirm,
    taskErrors,
    descMaxChar,
    confirmDelete,
    setConfirm,
    setTask,
    handleSave,
    handleDelete,
    setConfirmDelete,
  };
}
