import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export default function ReportInfoBox({ report }) {
  return (
    <Box>
      <Box
        sx={{
          border: 1,
          borderRadius: 1,
          padding: 1,
          marginBottom: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>Status: </b>
          </Box>
          {report.status}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>Date: </b>
          </Box>
          {new Date(
            `${new Date(report?.submitDate).toLocaleString()} UTC`
          ).toLocaleString()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>User: </b>
          </Box>
          {`${report?.user?.firstName} ${report?.user?.lastName}`}
        </Typography>
      </Box>
    </Box>
  );
}

ReportInfoBox.propTypes = {
  report: PropTypes.object.isRequired,
};
