import { useState, useEffect } from "react";

import {
  CREATE_NEW_POSITION,
  GET_ALL_POSITIONS,
  UPDATE_POSITION,
} from "../../shared/apiUrls";
import {
  apiPostAuth,
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { defaultUserPermissions } from "../pages/constants";

const emptyNewPosition = {
  name: "",
  type: null,
  managerUUID: null,
  defaultPermission: defaultUserPermissions,
};

export default function usePositions(setAddModalVisible) {
  const [positions, setPositions] = useState([]);
  const [newPosition, setNewPosition] = useState(emptyNewPosition);
  const [editPosition, setEditPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newPositionErrors, setNewPositionErrors] = useState({});
  const [editPositionErrors, setEditPositionErrors] = useState({});

  const handleUpdateNewPosition = (e) => {
    const { name, value } = e.target;
    setNewPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePosition = (e) => {
    const { name, value } = e.target;
    setEditPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetAllPositions = async () => {
    const results = await apiGetAuth(GET_ALL_POSITIONS);
    setPositions(results);
    setLoading(false);
  };

  const handleSubmit = async (callback) => {
    const response = await apiPostAuth(CREATE_NEW_POSITION, newPosition);

    if (response.name) {
      setPositions((prevState) => [response, ...prevState]);
      setNewPosition(emptyNewPosition);
      setAddModalVisible(false);
      callback();
    }
  };

  const handleUpdate = async (callback) => {
    const response = await apiPatchAuth(UPDATE_POSITION, {
      ...editPosition,
      manager: null,
    });

    if (response.name) {
      const posIdx = positions.findIndex((c) => c.uuid === response.uuid);
      const tempArr = [...positions];
      tempArr[posIdx] = response;

      setPositions(tempArr);
      setEditPosition(null);
      setAddModalVisible(false);
      callback();
    }
  };

  const handleSetPositionPermissions = (permissionName) => {
    setNewPosition((prevState) => ({
      ...prevState,
      defaultPermission: {
        ...prevState.defaultPermission,
        [permissionName]: !prevState?.defaultPermission[permissionName],
      },
    }));
  };

  const handleUpdatePositionPermissions = (permissionName) => {
    setEditPosition((prevState) => ({
      ...prevState,
      defaultPermission: {
        ...prevState.defaultPermission,
        [permissionName]: !prevState?.defaultPermission[permissionName],
      },
    }));
  };

  const handleSetEditPosition = (idx) => {
    const edit = positions?.[idx];
    setEditPosition(edit);
    setAddModalVisible(true);
  };

  const handleValidatePosition = () => {
    let errors = false;
    if (!newPosition.name || newPosition.name?.trim() === "") {
      errors = true;
      setNewPositionErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
    } else {
      setNewPositionErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    if (!newPosition.type) {
      errors = true;
      setNewPositionErrors((prevState) => ({
        ...prevState,
        type: true,
      }));
    } else {
      setNewPositionErrors((prevState) => ({
        ...prevState,
        type: false,
      }));
    }

    return errors;
  };

  const handleValidateEditPosition = () => {
    let errors = false;
    if (!editPosition.name || editPosition.name?.trim() === "") {
      errors = true;
      setEditPositionErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
    } else {
      setEditPositionErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    if (!editPosition.type) {
      errors = true;
      setEditPositionErrors((prevState) => ({
        ...prevState,
        type: true,
      }));
    } else {
      setEditPositionErrors((prevState) => ({
        ...prevState,
        type: false,
      }));
    }

    return errors;
  };

  useEffect(() => {
    handleGetAllPositions();
  }, []);

  return {
    loading,
    positions,
    newPosition,
    editPosition,
    newPositionErrors,
    editPositionErrors,
    handleSetEditPosition,
    setEditPosition,
    setNewPosition,
    handleUpdateNewPosition,
    handleUpdatePosition,
    handleValidatePosition,
    handleValidateEditPosition,
    handleSubmit,
    handleUpdate,
    handleSetPositionPermissions,
    handleUpdatePositionPermissions,
  };
}
