import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import LSButton, { buttonVariants } from "./buttons/LSButton";
import CompleteTaskModal from "./modals/CompleteTaskModal";

export default function CompleteTaskButton({
  tasks,
  setCounter,
  handleCompleteForm,
}) {
  const [open, setOpen] = useState(false);
  const permissions = useSelector((state) => state.permissions);

  const myNextTask = tasks.findIndex(
    (c) => c.userUUID === permissions.userUUID && c.status !== "Complete"
  );

  console.log({ tasks, permissions, myNextTask });

  return (
    <Box sx={{ marginBottom: 2 }}>
      {myNextTask >= 0 && (
        <>
          <LSButton
            onPress={() => setOpen(true)}
            variant={buttonVariants.primary}
            text="Complete My Task"
            customStyles={{ flex: 1 }}
          />
          <CompleteTaskModal
            modalVisible={open}
            setModalVisible={setOpen}
            task={tasks[myNextTask]}
            setCounter={setCounter}
            handleCompleteForm={handleCompleteForm}
          />
        </>
      )}
    </Box>
  );
}

CompleteTaskButton.propTypes = {
  tasks: PropTypes.array.isRequired,
  setCounter: PropTypes.func.isRequired,
  handleCompleteForm: PropTypes.func.isRequired,
};
