import PropTypes from "prop-types";
import React from "react";
import {
  Modal,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
} from "react-native";

export default function LSModal({ children, visible, setVisible, modalWidth }) {
  const { width } = useWindowDimensions();
  return (
    <Modal
      visible={visible}
      transparent
      onRequestClose={() => setVisible(false)}
      style={{ position: "relative", width: 400 }}
    >
      <View
        style={{
          ...styles.cardContainerStyles,
          right: width / 2 - modalWidth / 2,
        }}
      >
        {children}
      </View>
      <Pressable
        style={styles.overlayStyles}
        onPress={() => setVisible(false)}
      />
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlayStyles: {
    backgroundColor: "black",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
    opacity: 0.7,
  },
  cardContainerStyles: {
    backgroundColor: "black",
    borderRadius: 16,
    position: "absolute",
    top: 100,
  },
});

LSModal.propTypes = {
  children: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  modalWidth: PropTypes.number,
};

LSModal.defaultProps = {
  modalWidth: 310,
};
