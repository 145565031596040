import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
import React from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { useSearchParams } from "react-router-dom";

import FormSender from "./FormSender";
import { incidentReportSubmitStyles } from "./styles";
import { questionTypeCodes } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import FileViewer from "../../components/formInputs/FileViewer";
import InputFileUpload from "../../components/formInputs/InputFileUpload";
import LSTextField from "../../components/formInputs/LSTextField";
import MultipleSelectCheckMarks from "../../components/formInputs/MultipleSelectCheckmarks";
import PageLayout from "../../components/layouts/PageLayout";
import LSSpinner from "../../components/spinner/LSSpinner";
import useForm from "../../hooks/useForm";

// eslint-disable-next-line import/order, import/no-extraneous-dependencies
import { Box, Button, Card, Typography } from "@mui/material";

import useFormController from "../../hooks/useFormController";
import { getFormattedDate } from "../../utils/utils";
import { pageAppTypes } from "../constants";

export default function IncidentsFormSubmit() {
  const [searchParams] = useSearchParams();
  const formID = searchParams.get("form");
  const submissionID = searchParams.get("formSubmitUUID");
  const taskID = searchParams.get("taskUUID");
  const answerSubmissionUUID = searchParams.get("answerSubmissionUUID");
  const { form, loadingForm } = useForm(formID);
  const {
    handlePressNext,
    handlePressBack,
    handleAnswerChange,
    handleSubmitReport,
    question,
    answer,
    showSummary,
    formSubmit,
    answerErrors,
    submitting,
  } = useFormController(form);

  const { height, width } = useWindowDimensions();

  const setQuestionFormResponse = () => {
    if (
      question.typeCode === questionTypeCodes.multiple ||
      question.typeCode === questionTypeCodes.dropdown
    ) {
      return (
        <MultipleSelectCheckMarks
          id={question.uuid}
          options={question.answerOptions.map((option) => ({
            key: option.uuid,
            value: option.text,
          }))}
          handleChangeAnswer={handleAnswerChange}
          formData={answer}
          fieldName="answerOptions"
          errors={answerErrors}
        />
      );
    }
    if (question.typeCode === questionTypeCodes.dataSelect) {
      return (
        <MultipleSelectCheckMarks
          id={question.uuid}
          options={question.answerOptions.map((option) => ({
            key: option.uuid,
            value: option.text,
          }))}
          handleChangeAnswer={handleAnswerChange}
          formData={answer}
          fieldName="answerOptions"
          errors={answerErrors}
        />
      );
    }
    if (question.typeCode === questionTypeCodes.calendar) {
      if (!answer.selectedDate) {
        handleAnswerChange(new Date());
      }
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Controlled picker"
            value={dayjs(
              answer.selectedDate
                ? answer.selectedDate.toISOString()
                : new Date().toISOString()
            )}
            onChange={(newValue) => handleAnswerChange(newValue.$d)}
          />
        </LocalizationProvider>
      );
    }
    if (question.typeCode === questionTypeCodes.text) {
      return (
        <LSTextField
          errors={answerErrors}
          answer={answer}
          handleAnswerChange={handleAnswerChange}
        />
      );
    }
    if (question.typeCode === questionTypeCodes.paragraph) {
      return (
        <LSTextField
          errors={answerErrors}
          answer={answer}
          handleAnswerChange={handleAnswerChange}
          multiline
        />
      );
    }
    if (question.typeCode === questionTypeCodes.formSender) {
      return (
        <FormSender
          errors={answerErrors}
          question={question}
          answer={answer}
          handleAnswerChange={handleAnswerChange}
        />
      );
    }
    if (question.typeCode === questionTypeCodes.upload) {
      return (
        <InputFileUpload
          errors={answerErrors}
          handleUploadFile={handleAnswerChange}
          answer={answer}
          name="files"
        />
      );
    }
    return "";
  };

  const handleDisplayAnswer = (_question, _answer) => {
    if (
      _question.typeCode === questionTypeCodes.text ||
      _question.typeCode === questionTypeCodes.paragraph
    ) {
      return (
        <Typography variant="body1" sx={{ marginLeft: "8px" }}>
          <b>A:</b> {_answer.text}
        </Typography>
      );
    }
    if (_question.typeCode === questionTypeCodes.calendar) {
      return (
        <Typography variant="body1" sx={{ marginLeft: "8px" }}>
          <b>A:</b> {getFormattedDate(new Date(_answer.selectedDate))}
        </Typography>
      );
    }
    if (
      _question.typeCode === questionTypeCodes.multiple ||
      _question.typeCode === questionTypeCodes.dataSelect
    ) {
      return _answer.answerOptions?.map((_answerOption, idx) => (
        <Typography
          key={_answerOption.key}
          variant="body1"
          sx={{ marginLeft: "8px" }}
        >
          <b>{idx + 1}:</b> {_answerOption.value}
        </Typography>
      ));
    }
    if (_question.typeCode === questionTypeCodes.formSender) {
      return _answer.witnesses?.map((email, idx) => (
        <Typography key={email} variant="body1" sx={{ marginLeft: "8px" }}>
          <b>{idx + 1}:</b> {email}
        </Typography>
      ));
    }
    if (_question.typeCode === questionTypeCodes.upload) {
      const fileDisplay = [];
      for (let i = 0; i < _answer?.files?.length; i += 1) {
        const file = _answer.files[i];
        fileDisplay.push(
          <Box
            key={`${file.name}-${i}`}
            sx={{ marginLeft: "8px", marginBottom: "12px" }}
          >
            <FileViewer file={file} />
          </Box>
        );
      }
      return fileDisplay;
    }
    return "";
  };

  const handleShowSummary = () => {
    if (submitting === 1) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LSSpinner />
        </Box>
      );
    }
    if (submitting === 2) {
      return (
        <Box sx={{ marginTop: "64px" }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Success
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "body1",
              fontSize: "24px",
              color: "#aaa",
              textAlign: "center",
            }}
          >
            Your response has been recorded
          </Typography>
        </Box>
      );
    }
    return (
      <Box sx={{ height: height - 360, overflow: "scroll" }}>
        {formSubmit.answers.map((a, idx) => {
          const questionIdx = form.questionsList.findIndex(
            (q) => q.uuid === a.questionId
          );
          const q = form.questionsList[questionIdx];
          return (
            <Box
              sx={{ pb: 1, mb: 1, borderBottom: "2px solid lightgrey" }}
              key={a.questionId}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                <b>Q{idx + 1}:</b> {q.text}
              </Typography>
              {handleDisplayAnswer(q, a)}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <View style={styles.bodyStyles}>
        {loadingForm ? (
          <View style={styles.spinnerContainerStyles}>
            <LSSpinner />
          </View>
        ) : (
          <Card
            sx={{
              ...incidentReportSubmitStyles.cardStyles,
              width: width > 500 ? 400 : "90%",
            }}
          >
            <Box
              sx={{
                ...incidentReportSubmitStyles.cardBodyStyles,
                minHeight: height - 240,
              }}
            >
              {submitting === 0 && (
                <Typography
                  variant="h6"
                  sx={incidentReportSubmitStyles.cardHeaderTestStyles}
                >
                  {showSummary
                    ? "Review"
                    : `${question.text.trim()}${
                        question.requiresAnswer ? "*" : ""
                      }`}
                </Typography>
              )}
              {showSummary ? handleShowSummary() : setQuestionFormResponse()}
            </Box>
            <Box sx={incidentReportSubmitStyles.cardFooterStyles}>
              <Button
                size="small"
                sx={incidentReportSubmitStyles.backButtonStyles}
                variant="outlined"
                onClick={handlePressBack}
                disabled={
                  (answer.idxPath.length === 1 &&
                    answer.idxPath[0].questionIdx === 0) ||
                  submitting > 0
                }
              >
                Back
              </Button>
              <Button
                sx={incidentReportSubmitStyles.nextButtonStyles}
                onClick={() =>
                  showSummary
                    ? handleSubmitReport(
                        submissionID,
                        answerSubmissionUUID,
                        taskID
                      )
                    : handlePressNext(answer)
                }
                variant="contained"
                disabled={submitting > 0}
              >
                {showSummary ? "Submit" : "Next"}
              </Button>
            </Box>
          </Card>
        )}
      </View>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  bodyStyles: {
    width: "100%",
    alignItems: "center",
  },
});
