export const taskTypes = [
  { key: 1, value: "Form Submission" },
  { key: 2, value: "File Upload" },
  { key: 3, value: "Other Task" },
  { key: 4, value: "Review and Close" },
];

export const completionTimelineOptions = [
  { key: 1, value: "After Previous Task" },
  { key: 2, value: "Any Time" },
];

export const promptOptions = [
  { key: -1, value: "None" },
  { key: 0, value: "Immediately" },
  { key: 24, value: "24 hours after submission" },
  { key: 48, value: "48 hours after submission" },
  { key: 72, value: "72 hours after submission" },
];
