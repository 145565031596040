import { Box, Paper } from "@mui/material";
import React from "react";

import { pageNames, urlRoutes } from "./constants";
import LSLogo from "../../assets/StackedLogo.svg";
import { isWeb } from "../../utils/environments";
import LSButton, { buttonVariants } from "../components/buttons/LSButton";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";
import { mockNavigationWeb } from "../utils/mockNavigationWeb";

const welcomeText = "Welcome to LinkStep";

export default function Splash() {
  const { useNavigation } = isWeb
    ? mockNavigationWeb
    : // eslint-disable-next-line global-require
      require("@react-navigation/native");

  const navigation = useNavigation();

  const handleLoginPress = () => {
    if (isWeb) {
      window.location.href = urlRoutes.login;
    } else {
      navigation.navigate("login");
    }
  };

  const handleRegisterPress = () => {
    if (isWeb) {
      window.location.href = urlRoutes.register;
    } else {
      navigation.navigate(pageNames.register);
    }
  };

  return (
    <PageLayout center>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          minHeight: "85vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            width: "350px",
          }}
        >
          <img src={LSLogo} alt="LinkStep Logo" />
          <LSText
            text={welcomeText}
            variant={textVariant.h1}
            customStyles={{ textAlign: "center", marginBottom: "24px" }}
          />
          <LSButton
            text="Login"
            onPress={handleLoginPress}
            variant={buttonVariants.primary}
          />
          <LSButton
            text="Register"
            onPress={handleRegisterPress}
            variant={buttonVariants.secondary}
          />
        </Paper>
      </Box>
    </PageLayout>
  );
}
