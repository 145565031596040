import {
  SupervisorAccount,
  Dashboard,
  FileCopy,
  SettingsApplications,
  GroupAdd,
  Business,
  WarningAmber,
  Report,
  Photo,
  Receipt,
  LocalShipping,
  Inventory,
  Upcoming,
  AccountTree,
} from "@mui/icons-material";

import { urlRoutes } from "../../pages/constants";

export const navItems = [
  { name: "Dashboard", path: urlRoutes.home, icon: <Dashboard /> },
  {
    name: "Admin",
    icon: <SupervisorAccount />,
    subItems: [
      {
        name: "Company Profile",
        path: urlRoutes.companySettings,
        icon: <SettingsApplications />,
      },
      {
        name: "Users",
        path: urlRoutes.usersSettings,
        icon: <GroupAdd />,
      },
      {
        name: "Positions",
        path: urlRoutes.companyPositions,
        icon: <AccountTree />,
      },
      {
        name: "Locations",
        path: urlRoutes.locationsSettings,
        icon: <Business />,
      },
    ],
  },
  {
    name: "Incident Reports",
    icon: <WarningAmber />,
    subItems: [
      {
        name: "Form",
        path: urlRoutes.incidentsForms,
        icon: <FileCopy />,
      },
      {
        name: "Submitted Reports",
        path: urlRoutes.incidentsReports,
        icon: <Report />,
      },
    ],
  },
  {
    name: "Coming Soon",
    icon: <Upcoming />,
    subItems: [
      {
        name: "Photo Gallery",
        path: urlRoutes.photoGallery,
        icon: <Photo />,
      },
      {
        name: "Inventory Management",
        path: urlRoutes.inventoryManagement,
        icon: <Inventory />,
      },
      {
        name: "Asset Management",
        path: urlRoutes.assetManagement,
        icon: <LocalShipping />,
      },
      {
        name: "Receipt Tracker",
        path: urlRoutes.receiptTracker,
        icon: <Receipt />,
      },
    ],
  },
];

export const hi = "hello";
