import { FontAwesome } from "@expo/vector-icons";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { Button, Box, Paper } from "@mui/material";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

import { BASE_URL } from "../../../shared/apiUrls";
import { fontColorDark, green } from "../../../theme/colors";
import LSButton, { buttonVariants } from "../../components/buttons/LSButton";
import Card, { cardVariant } from "../../components/cards/Card";
import InputFileUpload from "../../components/formInputs/InputFileUpload";
import LSInput, { inputVariants } from "../../components/formInputs/LSInput";
import PageLayout from "../../components/layouts/PageLayout";
import LSModal from "../../components/modals/LSModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useLocations from "../../hooks/useLocations";
import { pageAppTypes } from "../constants";

const pageTitle = "Manage Locations";

export default function LocationsSettings() {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const {
    locations,
    loading,
    locationErrors,
    newLocation,
    editLocation,
    editLocationErrors,
    setNewLocation,
    setEditLocation,
    handleGenerateLocationPress,
    handleEditLocationSubmit,
  } = useLocations(setAddModalVisible);

  const handleLocationClick = (location) => {
    console.log("show location profile", location);
    setEditLocation(location);
    setAddModalVisible(true);
  };

  const handleAddNewLocation = () => {
    setAddModalVisible(true);
  };

  const handleProfileSelect = (files) => {
    if (editLocation) {
      setEditLocation((prevState) => ({
        ...prevState,
        files: [files?.[0]],
      }));
    } else {
      setNewLocation((prevState) => ({
        ...prevState,
        files: [files?.[0]],
      }));
    }
  };

  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <View style={styles.headerContainerStyles}>
        <LSText
          variant={textVariant.h1}
          text={pageTitle}
          color={fontColorDark}
        />
        <Button
          sx={{
            borderRadius: "50%",
            padding: 2,
            height: "60px",
            width: "40px",
            backgroundColor: green[400],
            "&:hover": {
              backgroundColor: green[100],
              color: "#3c52b2",
            },
          }}
          onClick={handleAddNewLocation}
        >
          <FontAwesome name="plus" size={25} color={fontColorDark} />
        </Button>
      </View>
      {loading ? (
        <View style={styles.spinnerContainerStyles}>
          <LSSpinner />
        </View>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "-8px",
          }}
        >
          {locations?.map((location) => {
            const { name, address, city, state, zip, uuid, profileImagePath } =
              location;
            return (
              <Paper
                key={uuid}
                elevation={4}
                sx={{ width: "250px", padding: 2, margin: 1 }}
                onClick={() => handleLocationClick(location)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: 3,
                      height: "150px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: profileImagePath ? "auto" : "lightGray",
                    }}
                  >
                    {profileImagePath ? (
                      <img
                        style={{ width: "100%" }}
                        src={`${BASE_URL}${profileImagePath}`}
                        alt=""
                      />
                    ) : (
                      <HomeWorkIcon sx={{ fontSize: "150px" }} />
                    )}
                  </Box>
                  <LSText
                    variant={textVariant.h4}
                    text={name}
                    color={fontColorDark}
                    customStyles={{ marginBottom: 8 }}
                  />
                  <LSText
                    variant={textVariant.text}
                    text={address}
                    color={fontColorDark}
                  />
                  <LSText
                    variant={textVariant.text}
                    text={`${city}, ${state} ${zip}`}
                    color={fontColorDark}
                  />
                </Box>
              </Paper>
            );
          })}
        </Box>
      )}

      <LSModal
        visible={addModalVisible}
        setVisible={(val) => {
          setEditLocation(null);
          setAddModalVisible(val);
        }}
      >
        <Card
          variant={cardVariant.primary}
          customStyles={{ width: 310, maxWidth: "100%" }}
        >
          <View style={{ width: "100%", marginBottom: 32 }}>
            <LSText
              text={editLocation ? "Edit Location" : "Add New Location"}
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, textAlign: "center" }}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={editLocation ? setEditLocation : setNewLocation}
              object={editLocation || newLocation}
              name="name"
              placeholder="Name"
              errorObj={editLocation ? editLocationErrors : locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={editLocation ? setEditLocation : setNewLocation}
              object={editLocation || newLocation}
              name="address"
              placeholder="Address"
              errorObj={editLocation ? editLocationErrors : locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={editLocation ? setEditLocation : setNewLocation}
              object={editLocation || newLocation}
              name="city"
              placeholder="City"
              errorObj={editLocation ? editLocationErrors : locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={editLocation ? setEditLocation : setNewLocation}
              object={editLocation || newLocation}
              name="state"
              placeholder="State"
              errorObj={editLocation ? editLocationErrors : locationErrors}
              customStyles={styles.inputStyles}
            />
            <LSInput
              variant={inputVariants.primary}
              setter={editLocation ? setEditLocation : setNewLocation}
              object={editLocation || newLocation}
              name="zip"
              placeholder="Zip"
              errorObj={editLocation ? editLocationErrors : locationErrors}
              customStyles={styles.inputStyles}
            />
            <InputFileUpload
              handleUploadFile={handleProfileSelect}
              answer={editLocation || newLocation}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <LSButton
              onPress={() => setAddModalVisible(false)}
              text="Cancel"
              variant={buttonVariants.secondary}
              customStyles={{ marginRight: 16, flex: 1 }}
            />
            <LSButton
              onPress={
                editLocation
                  ? handleEditLocationSubmit
                  : handleGenerateLocationPress
              }
              variant={buttonVariants.primary}
              text="Save"
              customStyles={{ flex: 1 }}
            />
          </View>
        </Card>
      </LSModal>
    </PageLayout>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 16 },
  submissionContainer: {
    borderBottomColor: fontColorDark,
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 8,
  },
  submissionContainerLeft: {
    flex: 1,
  },
});
